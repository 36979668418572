import osintAxios from "@/axios/osint";
import {getBackgroundStatus} from "@/utils/monitoringTools.js";
import Badge from "@/components/badge";
import neoSwitch from "@/components/toggle-switch";
import {mapGetters} from "vuex";
import axios from "@/axios";
import NeoBadgeList from "@/components/badge-list";
import {DateTime} from "luxon";
import ImagePlaceholder from "@/components/image-placeholder";
import {FEATURES} from "@/utils/constants";

export default {
    name: "search-engine-card",
    props: ["data", "onClick", "clickedLinks", "onHandleMarkAsDone", "isDoneLoading", "queryId", "searchedData", "analyzerCount", "currentTab", "autorun", "autoAnalyser"],
    data() {
        return {
            analysisStart: false,
            dataValue: this.searchedData,
        };
    },
    methods: {
        getBackgroundStatus,
        permissionExists(key) {
            return this.getPermissions.find((res) => FEATURES.tools[res] == key);
        },
        async startAnalyzer(cardData) {
            let config = {};
            this.getAnalyzerOptions.forEach((res) => {
                if (this.permissionExists(res.key)) {
                    config[res.payload_name] = res.selected ? res.selected : res.analysis;
                }
            });
            this.analysisStart = true;
            const url = `/case-analysis/${cardData.case_id}/analysis`;
            try {
                await axios.post(url, {
                    record_count: 1,
                    tool: "search-engine-tool",
                    queries: [
                        {
                            query_id: cardData.query_id ? cardData.query_id : this.queryId,
                            doc_ids: [cardData._id],
                        },
                    ],
                    config: config,
                });
                this.startAnalyzerInInterval(cardData._id);
            } catch (err) {
                this.$toast.error("Something went wrong.");
                this.analysisStart = false;
            }
        },
        startAnalyzerInInterval(cardId) {
            this.$emit("handleAnalysisStatus", {id: cardId});
        },
        async showPreview() {
            this.previewItem = this.data;
            const response = await osintAxios.post("/search-engine/preview", {
                item: this.isMonitoringTab ? this.data.mongo_doc_id : this.data._id,
                case_id: this.data.case_id,
            });

            if (response.data.status) {
                this.showPreviewModal = true;
                let yourWindow = window.open();
                yourWindow.opener = null;
                yourWindow.location = response.data.preview;
                yourWindow.target = "_blank";
            } else {
                this.$toast.error(response.data.message);
            }
        },
        getResultFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        handleOnClick(link, id) {
            // window.open(link, "_blank", "noopener,noreferrer");
            open(link, "_blank", "noopener,noreferrer");
            this.onClick(link, id);
        },
        visitedClass(data, clickedLinks) {
            return data.visited || clickedLinks.find((ele) => ele.links.includes(data.link)) || (this.getReadOnlyMode && "tw-cursor-not-allowed");
        },
        checkedInput(data, clickedLinks) {
            return data.done || clickedLinks.find((ele) => ele.doneLinks.includes(data.link)) || data.changes_viewed;
        },
        disabledCheck(data, clickedLinks) {
            return data.visited || this.getReadOnlyMode || clickedLinks.find((ele) => ele.links.includes(data.link)) || data.isDoneLoading || this.isDoneLoading;
        },
        async handleMonitoring() {
            // event.stopPropagation();
            this.data.monitoring_status = !this.data.monitoring_status;
            this.$forceUpdate();
            await axios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.data._id,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: this.data._id,
                card_source: this.$route.params.toolName,
                status: this.data.monitoring_status ? "enabled" : "disabled",
            });
        },
        handleMarkAsDone(event, data) {
            if (this.isMonitoringTab) {
                this.onHandleMarkAsDone(event, data._id, data.link, data);
            } else {
                this.onHandleMarkAsDone(event, data._id, data.link, data);
            }
        },
        fetchList(labels) {
            if (labels && labels.length > 0) {
                return labels.map((label) => ({label: label.category}));
            } else return [];
        },
        getSentimentStatusClass(status) {
            let classes = "";
            if (status === "Neutral") classes += "tw-bg-gray-500";
            else if (status === "Positive") classes += "tw-bg-green-500";
            else if (status === "Negative") classes += "tw-bg-red-500";
            return classes;
        },
    },
    computed: {
        ...mapGetters(["getProduct", "getAnalyzerOptions", "getPermissions", "getAutoAnalyze"]),
        getReadOnlyMode() {
            return this.$store.getters.getReadOnlyMode;
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        showAnalyser() {
            return this.getAnalyzerOptions.filter((res) => res.selected);
        },
        CompletedData() {
            let isNull = this.dataValue?.some((el) => el.analysis_status === "pending" || !el.analysis_status);
            return isNull;
        },
        autoAnalyser() {
            return this.getAutoAnalyze;
        },
    },
    components: {
        "neo-badge": Badge,
        neoSwitch,
        NeoBadgeList,
        ImagePlaceholder,
    },
};
